@import url('https://fonts.googleapis.com/css2?family=Italiana&display=swap');

html,
body {
    margin: 0;
    height: 0;
}

body {
    background-color: #faf6e8;
    font-family: 'Italiana', serif;
}

.App {
    margin: 0;
    padding: 0;
}

.noise-bg::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    opacity: 0.03;
    z-index: 1500;
    pointer-events: none;
    background: url('./assets/noise.gif');
}

::-webkit-scrollbar {
    display: none;
}

.cursor {
    display: inline-block;
    width: 1.5rem;
    height: 7rem;
    background: #d53f41;
    margin-left: 0.5rem;
    border-radius: 1px;
    animation: cursor 1s infinite;
}

@keyframes cursor {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* .arrow-container {
    position: relative;
}

.arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow span {
    display: block;
    width: 2rem;
    height: 2rem;
    border-bottom: 4px solid #d53f41;
    border-right: 4px solid #d53f41;
    transform: rotate(45deg);
    margin: -0.8rem;
    animation: scroll 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
} */

.btn {
    background-color: #f0f0f0;
    border: none;
    color: #181818;
    border-radius: 10px;
    font-size: 1.5rem;
    outline: none;
    transition: ease-out 0.3s;
    box-shadow: inset 0 0 0 0 #d53f41;
}

.btn a {
    text-decoration: none;
    color: #3d3d3d;
}

.btn a:hover {
    cursor: pointer;
    color: #f0f0f0;
    box-shadow: inset 300px 0 0 0 #d53f41;
}

@keyframes scroll {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1200;
    background-color: #faf6e8;
}

#loading-percent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    color: #f0f0f0;
    font-size: 9rem;
    font-weight: lighter;
    font-style: italic;
    mix-blend-mode: difference;
}

#loading-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

#bar-confirm {
    width: 1%;
    height: 100vh;
    background: #181818;
}

@media screen and (max-width: 900px) {
    .cursor {
        width: 1rem;
        height: 5rem;
    }
}

@media screen and (max-width: 600px) {
    .arrow span {
        width: 1rem;
        height: 1rem;
    }
    .cursor {
        width: 0.8rem;
        height: 4rem;
    }
}

@media screen and (max-width: 480px) {
    .arrow {
        margin-top: 15%;
    }
}
