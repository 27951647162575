.container {
    position: relative;
    margin-top: 10rem;
}

.my-name,
.my-role {
    color: #181818;
    line-height: 0;
    font-size: 12vw;
    letter-spacing: -0.5vw;
}

.header-name {
    padding-top: 2rem;
    text-align: right;
    margin-right: 0.5rem;
}

.header-role {
    text-align: left;
}

@media screen and (max-width: 900px) {
    .container {
        margin-top: 8rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        margin-top: 3rem;
    }
    .my-name {
        line-height: 0.85;
    }
    .my-role {
        line-height: 0.75;
    }
    .my-name,
    .my-role {
        font-size: 17vw;
        width: 75%;
    }
    .header-name {
        margin-left: 50%;
    }
}

@media screen and (max-width: 480px) {
    .container {
        margin-top: 5rem;
    }
}
