.contact-section {
    position: relative;
    background-color: #181818;
    margin-top: 13rem;
    height: 75vh;
    padding: 15vh 8vw 10vh 8vw;
    color: #f0f0f0;
}

.contact-liner {
    font-size: 6rem;
    line-height: 0.8;
}

.contact-text {
    margin: 2rem 12rem 4rem 12rem;
    font-size: 1.5rem;
    line-height: 0.85;
}

form .field {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

form input:focus,
form textarea:focus {
    outline: none;
}

input,
textarea {
    color: #faf6e8;
    background: transparent;
    margin: 0.35rem;
    border: none;
    border-bottom: 1px solid #d53f41;
    font-size: 1.5rem;
}

input {
    width: 85%;
}

textarea {
    width: 185%;
}

.contact-btn:hover {
    cursor: pointer;
    color: #f0f0f0;
    box-shadow: inset 300px 0 0 0 #d53f41;
}

@media screen and (max-width: 768px) {
    .contact-liner {
        font-size: 4rem;
    }
    .contact-text {
        margin: 3rem 4rem 5rem 4rem;
        font-size: 1rem;
    }
    input,
    textarea {
        font-size: 1rem;
    }
    .btn {
        font-size: 1rem;
    }
}
