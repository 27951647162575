.about-container {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 5rem;
}

.anchor-text {
 color: #d53f41;
 text-decoration: none;
}

.about-page {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.about-text {
    line-height: 0;
    font-size: 12vw;
    letter-spacing: -0.5vw;
    color: #181818;
}

.myself {
    margin-top: -3rem;
}

.container-text {
    font-size: 3vw;
    color: #3d3d3d;
    font-weight: lighter;
}

.text1 {
    margin-left: 10%;
}

.text2 {
    margin-left: 30%;
}

.text3 {
    margin-left: 10%;
}

.sub-about {
    line-height: 0;
    font-size: 8vw;
    letter-spacing: -0.5vw;
    color: #181818;
}

.dog-about {
    display: flex;
    align-items: center;
}

.about2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5rem;
}

@media screen and (max-width: 1240px) {
    img {
        width: 85%;
    }
}

@media screen and (max-width: 900px) {
    .text1 {
        top: 100vh;
    }

    .text2 {
        top: 120vh;
    }

    .text3 {
        top: 140vh;
    }
    .about2 {
        top: 170vh;
    }
    .about-container {
        margin-top: 6rem;
    }
}

@media screen and (max-width: 600px) {
    .about-container {
        margin-top: 10rem;
        margin-bottom: 0rem;
    }
    .about-page {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .about-text {
        font-size: 16vw;
    }
    .dog-about {
        flex-direction: column-reverse;
    }
    .myself {
        margin-top: -1rem;
    }
    .container-text {
        font-size: 4vw;
    }
}

@media screen and (max-width: 480px) {
    .about-text {
        font-size: 17vw;
    }
    .myself {
        margin-top: -2rem;
    }

    .dog {
        width: 75%;
    }

    .text2 {
        left: 25%;
    }

    .container-text {
        font-size: 5vw;
    }
}
