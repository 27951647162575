.main-skills-reel {
    width: 100%;
    overflow: hidden;
}

.skills-reel {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: fit-content;
    will-change: transform;
    animation: animation-reel 60s infinite linear;
}

@keyframes animation-reel {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

.reel {
    font-weight: 400;
    font-size: 12vw;
}

.skills-boxes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 6rem;
    width: 100%;
    height: fit-content;
    flex-wrap: wrap;
}

.skill-box {
    margin: 3rem 0.6rem;
    display: flex;
    width: 22rem;
    height: 12.25rem;
    color: #f0f0f0;
    flex-direction: column;
    background-color: #181818;
    border: 1px solid transparent;
    transition: color 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    transition: background-color 1s cubic-bezier(0.16, 1, 0.3, 1);
    transition: border 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}

.skill-box:hover {
    color: #181818;
    background-color: #f0f0f0;
    border: 1px solid #181818;
}

.skill-title,
.skill-desc {
    margin: 0 2rem;
    font-weight: lighter;
}

.skill-title {
    margin-top: 2rem;
    font-size: 1.5rem;
}

.skill-desc {
    margin-top: 1.5rem;
    font-size: 0.9rem;
}

@media screen and (max-width: 600px) {
    .skills-reel {
        margin-top: 5rem;
    }
}
